import Paper from "@material-ui/core/Paper";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React from "react";

import useAxiosAuth from "../../utils/axios/useAxiosAuth";
import SubDestination from "./subDestination";

const Destinations = () => {
  const [value, setValue] = React.useState(0);

  const { data: destination } = useAxiosAuth(`Destination`);

  return (
    <div className="max-w-screen-2xl mx-auto lg:px-0 sm:px-10 px-5">
      <h1 className="pt-24 sm:text-5xl text-4xl text-[#1D306D] font-bold text-center">
        Our Holiday Destinations
      </h1>
      <Paper className="pt-12" square>
        <Tabs
          value={value}
          textColor="primary"
          indicatorColor="primary"
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          className="mx-auto "
          centered
        >
          {destination.map((item, index) => (
            <Tab key={index} label={item.name} />
          ))}
        </Tabs>
        {destination[value] === undefined ? (
          ""
        ) : (
          <SubDestination data={destination[value]} />
        )}
      </Paper>
    </div>
  );
};

export default Destinations;
