import React from "react";

const Memorable = () => {
  return (
    <div className="bg-[#F7F7F7] py-20 ">
      <div className="max-w-screen-2xl mx-auto md:px-12 px-5 ">
        <h1 className="text-[30px] lg:text-[56px] text-[#1D306D] xl:w-[60%] mx-auto text-center lg:mb-20">
          Make Your Holidays Memorable With Drukair Holidays
        </h1>
        <div className="lg:grid lg:grid-cols-[40%_60%] lg:gap-4">
          <div className="my-4 w-[80%] sm:w-[60%] lg:w-[90%] mx-auto lg:mx-0">
            <img
              src={require("../../assets/images/tourPackageMemorable.png")}
              alt="Tour Package Memorable"
              className="w-full h-auto max-h-[90vh] object-cover"
            />
          </div>
          <div className=" mx-auto lg:w-full text-[#4B4F52] text-[20px] text-justify lg:mx-0">
            <p>
              Make your memories soar with Drukair Holidays! Embark on
              unforgettable journeys and explore breathtaking destinations with
              our tailored travel experiences. Whether it's the majestic peaks
              of Bhutan or the serene landscapes of neighboring countries, let
              us curate the perfect getaway for you. From seamless travel
              arrangements to immersive cultural experiences, every moment with
              Drukair Holidays is crafted to create lasting memories. Discover
              the world with us and make every adventure a story worth telling.
              Start your journey today!
            </p>
            <br />
            <br />
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 text-center">
              <div className="shadow-xl border rounded-lg p-5 bg-white">
                <img
                  src={require("../../assets/images/affordablePrice.png")}
                  alt="Affordable Prices"
                  className="w-[72px] h-[72px] object-cover mx-auto my-4"
                />
                <span className="py-4">Affordable Prices</span>
              </div>
              <div className="shadow-xl border rounded-lg p-5 bg-white">
                <img
                  src={require("../../assets/images/excitingTrips.png")}
                  alt="Exciting Trips"
                  className="w-[72px] h-[72px] object-cover mx-auto my-4"
                />
                <span className="">Exciting Trips</span>
              </div>
              <div className="shadow-xl border rounded-lg p-5 bg-white">
                <img
                  src={require("../../assets/images/comfortZone.png")}
                  alt="Comfort Zone"
                  className="w-[72px] h-[72px] object-cover mx-auto my-4"
                />
                <span className="">Comfort Zone</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Memorable;
