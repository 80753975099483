import { useParams } from "react-router-dom";

import useAxiosAuth from "../utils/axios/useAxiosAuth";

const Content = () => {
  const { contentId } = useParams();

  const { data: content } = useAxiosAuth(`content/${contentId}`);

  return (
    <div className="max-w-screen-2xl mx-auto md:px-10 px-5 py-12 ">
      <div className="text-left">
        <h1 className="text-4xl font-bold text-primary">{content.name}</h1>
        <h2 className="text-lg pt-2 pb-8 text-secondary">{content.name}</h2>
      </div>
      <div
        className="content-data"
        dangerouslySetInnerHTML={{
          __html: content.description,
        }}
      ></div>
    </div>
  );
};

export default Content;
