import React from 'react'

import SustainabilityLanding from '../components/sustainability/sustainabilityLanding';
import TravelPower from '../components/sustainability/travelPower';
import Blogs from '../components/sustainability/blogs';

const sustainability = () => {
  return (
    <div className="bg-[#f7f7f7] scroll-smooth">
        <SustainabilityLanding />
        <TravelPower />
        <Blogs />
    </div>
  )
}

export default sustainability
