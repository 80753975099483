import axios from "axios";
import React, { useMemo, useState } from "react";
import { FaArrowRight } from "react-icons/fa";
import { IoLogoTwitter } from "react-icons/io";
import { TiSocialLinkedin } from "react-icons/ti";
import { TiSocialFacebook } from "react-icons/ti";
import { toast } from "react-toastify";

import footerSvg from "../../assets/images/footerSvg.svg";
import { BASE_URL } from "../../utils/axios/axios";
import useAxiosAuth from "../../utils/axios/useAxiosAuth";

const Footer = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("None");
  const [contact, setContact] = useState("None");
  const { data: contents } = useAxiosAuth(`content`);
  const { data: destination } = useAxiosAuth(`destination`);

  const footerContents = useMemo(() => {
    return contents.filter((content) => content.showOnFooter);
  }, [contents]);

  const year = new Date().getFullYear();

  const socials = [
    {
      icon: <TiSocialFacebook />,
      link: "https://www.facebook.com/DrukAirHolidaysSingapore",
    },
  ];

  const submitNews = (event) => {
    event.preventDefault();
    const payload = {
      email: email,
      name: name,
      contact: contact,
    };
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${BASE_URL}/api/newsletter/`,
      headers: {
        Authorization: `Basic ${window.btoa("frontend:WWEQgN9.%AAw]GfR")}`,
        "Content-Type": "application/json",
      },
      data: payload,
    };

    axios
      .request(config)
      .then((response) => {
        setEmail("");
        toast.success("Subscribed to Newsletter!");
      })
      .catch((error) => {
        console.log(error);
        toast.error("Failed to subscribe to newsletter.");
      });
  };
  return (
    <div className="relative">
      <img
        className="bottom-0 object-cover md:h-[25vh] h-[90vh] absolute opacity-5"
        src={footerSvg}
        alt=""
      />

      <footer className="bg-[#1D306D]">
        <div className="max-w-screen-2xl mx-auto relative z-[10] 2xl:px-5 md:px-12 px-5 pt-12 pb-5">
          <div className="border-b border-[#E9E9E9]/20 grid md:grid-cols-3 grid-cols-1 gap-7 pb-6">
            <div>
              <div>
                <img
                  className="w-[40%] pb-2"
                  src={require("../../assets/images/DrukairLogoWhite.png")}
                  alt="logo"
                />
              </div>

              <p className="mt-4 md:w-[20vw] text-white">
                Hello, we are Drukair Holidays, Our goal is to provide
                high-quality, authentic, and sustainable travel experiences in
                Bhutan
              </p>

              <ul className="mt-8 flex gap-6 ml-0">
                {socials.map((social, index) => (
                  <a
                    href={social.link}
                    rel="noreferrer"
                    target="_blank"
                    key={index}
                    className="text-lg border border-white rounded-3xl p-2 text-white"
                  >
                    {social.icon}
                  </a>
                ))}
              </ul>
            </div>

            <div className="grid xl:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-7 md:col-span-2">
              <div>
                <p className="font-bold text-white">Product</p>
                <ul className="mt-3 md:space-y-4 space-y-2 text-sm text-white">
                  <li>
                    <a href="/" className=" transition hover:opacity-75">
                      Home
                    </a>
                  </li>
                  {footerContents.length > 0 &&
                    footerContents.map((footerContent, index) => (
                      <li key={index}>
                        <a
                          href={`/content/${footerContent.id}`}
                          className=" transition hover:opacity-75"
                        >
                          {footerContent.name}
                        </a>
                      </li>
                    ))}
                </ul>
              </div>

              <div>
                <p className="font-bold text-white">Destinations</p>
                <ul className="mt-3 md:space-y-4 space-y-2 text-sm text-white">
                  {destination.map((item, index) => (
                    <li key={index}>
                      <a
                        className="transition hover:opacity-75"
                        href={`/destination/${item.id}`}
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>

              <div>
                <p className="font-bold text-white">Other Links</p>
                <ul className="mt-3 md:space-y-4 space-y-2 text-sm text-white">
                  <li>
                    <a href="/faq" className=" transition hover:opacity-75">
                      FAQ
                    </a>
                  </li>

                  <li>
                    <a
                      href="/packages"
                      className=" transition hover:opacity-75"
                    >
                      Packages
                    </a>
                  </li>

                  <li>
                    <a
                      href="/groupdeparture"
                      className=" transition hover:opacity-75"
                    >
                      Group Packages
                    </a>
                  </li>

                  <li>
                    <a
                      href="./contact"
                      className=" transition hover:opacity-75"
                    >
                      Contact Us
                    </a>
                  </li>
                </ul>
              </div>

              <div className="max-w-full p-6 lg:col-span-1 sm:col-span-2 col-span-1 bg-[#ffffff1a] text-white rounded-lg shadow md:mt-0 mt-10 h-fit">
                <h5 className="mb-2">Subscribe</h5>

                {/* TODO: Form Here
                    Search Field                
                */}
                <form action="" onSubmit={submitNews}>
                  <div className="flex">
                    <div className="relative w-full">
                      <input
                        type="search"
                        id="search-dropdown"
                        className="block p-2.5 w-full z-20 text-black text-sm bg-gray-50 focus-visible:outline-none rounded-s-lg "
                        placeholder="Email"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        required
                      />
                    </div>
                    <button
                      type="submit"
                      className="p-2.5 text-sm bg-secondary rounded-e-lg border-y border-r group"
                    >
                      <FaArrowRight className="group-hover:scale-125 transition ease-in-out" />
                    </button>
                  </div>
                  {/* <p className="text-sm pt-4">
                    Hello, we are Drukair Holidays. Our goal is to translate the
                    positive effects from revolutionizing how companies engage
                    with their clients & their team.
                  </p> */}
                </form>
              </div>
            </div>
          </div>

          <div className="flex flex-row justify-center text-sm space-x-10 pt-5 text-white">
            <h1>
              &copy; {year}. All Rights Reserved. Website by&nbsp;
              <a
                className="text-sky-300 hover:text-sky-200"
                href="https://octacore.com.np/"
                target="_blank"
                rel="noreferrer"
              >
                Octacore Solutions
              </a>
            </h1>
            {/* <h1>Privacy</h1>
            <h1>Cookies</h1> */}
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
