import axios from "axios";
import dayjs from "dayjs";
import React from "react";
import { useEffect, useMemo, useState } from "react";
import { FaPlaneDeparture } from "react-icons/fa";
import { FaPlaneArrival } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";

import { BASE_URL } from "../utils/axios/axios";
import useAxiosAuth from "../utils/axios/useAxiosAuth";

const Flight = () => {
  const [oneWay, setOneWay] = useState(true);

  // TODO: Reset these values after you are done fixing
  const [arrivalCity, setArrivalCity] = useState("");
  const [departureCity, setDepartureCity] = useState("");

  const [departureDate, setDepartureDate] = useState(dayjs());
  const [returnDate, setReturnDate] = useState(dayjs().add(7, "day"));

  const [submitted, setSubmitted] = useState(false);

  const [oneWayFlightsSchedule, setOneWayFlightsSchedule] = useState([]);
  const [returnFlightsSchedule, setReturnFlightsSchedule] = useState([]);

  const { data: flightRoutes } = useAxiosAuth("schedule/routes");

  const sevenDaysFromDepartureDate = useMemo(() => {
    const datesList = [];
    for (let i = 0; i < 7; i++) {
      datesList.push(departureDate.add(i, "day"));
    }
    return datesList;
  }, [departureDate]);
  const sevenDaysFromReturnDate = useMemo(() => {
    const datesList = [];
    for (let i = 0; i < 7; i++) {
      datesList.push(returnDate.add(i, "day"));
    }
    return datesList;
  }, [returnDate]);

  console.log(sevenDaysFromDepartureDate);

  const [salutations, setSalutations] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [noOfAdult, setNoOfAdult] = useState("");
  const [noOfChildren, setNoOfChildren] = useState("");
  const [yourMessage, setYourMessage] = useState("");

  // useEffect(() => {
  //   const departureDateInput = document.getElementById("departureDate");
  //   if (departureDateInput) {
  //     departureDateInput.addEventListener("focus", function () {
  //       this.type = "date";
  //     });
  //     departureDateInput.addEventListener("blur", function () {
  //       this.type = "text";
  //     });
  //   }
  //   const returnDateInput = document.getElementById("returnDate");
  //   if (returnDateInput) {
  //     returnDateInput.addEventListener("focus", function () {
  //       this.type = "date";
  //     });
  //     returnDateInput.addEventListener("blur", function () {
  //       this.type = "text";
  //     });
  //   }
  // }, []);

  const submitFlight = (event) => {
    event.preventDefault();
    let data = new FormData();
    // Package Details
    data.append("salutation", salutations);
    data.append("firstName", firstName);
    data.append("middleName", "");
    data.append("lastName", lastName);
    data.append("email", email);
    data.append("phoneNumber", mobileNumber);
    data.append("noOfAdults", noOfAdult);
    data.append("noOfChildren", noOfChildren);
    data.append("message", yourMessage);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${BASE_URL}/api/enquiry/`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setSalutations("");
        setFirstName("");
        setLastName("");
        setEmail("");
        setMobileNumber("");
        setNoOfAdult("");
        setNoOfChildren("");
        setYourMessage("");

        toast.success("Flight request recorded succesfully!");
      })
      .catch((error) => {
        console.log(error);
        toast.error("Flight request has failed!");
      });
  };

  return (
    <div className="max-w-screen-2xl mx-auto py-12 2xl:px-5 md:px-10 px-5">
      <div className="text-[#1D306D] mx-auto text-center py-10">
        <h1 className="text-[56px] ">Flights to Bhutan & Beyond</h1>
        <span className="text-[#8A94B2] text-[20px]">
          Start Finding Your Flight to Magical Kingdom of Bhutan
        </span>
      </div>

      {/* Flight for one way and round trip */}
      <div className="my-10 px-4">
        <div className="w-full md:w-[60vw] lg:w-[35%] bg-[#F5FBFB] grid grid-cols-2 gap-2 text-center rounded-t-xl ">
          <div
            className={`cursor-pointer mx-2 sm:mx-6 my-2 sm:my-4 py-2 sm:py-4 flex flex-row items-center justify-center rounded-lg ${oneWay ? "bg-[#1D306D]" : ""}`}
            onClick={() => {
              setOneWay(true);
              setSubmitted(false);
              console.log(submitted);
            }}
          >
            <FaPlaneDeparture
              className={`w-4 h-4 sm:w-6 sm:h-6 mb-1 sm:mb-2 ${oneWay ? "text-white" : "text-[#1D306D]"}`}
            />
            <span
              className={`px-1 sm:px-2 ${oneWay ? "text-white" : "text-[#1D306D]"}`}
            >
              One Way
            </span>
          </div>
          <div
            className={`cursor-pointer mx-2 sm:mx-6 my-2 sm:my-4 py-2 sm:py-4 flex flex-row items-center justify-center rounded-lg ${!oneWay ? "bg-[#1D306D]" : ""}`}
            onClick={() => {
              setOneWay(false);
              setSubmitted(false);
              console.log(submitted);
            }}
          >
            <FaPlaneArrival
              className={`w-4 h-4 sm:w-6 sm:h-6 mb-1 sm:mb-2 ${!oneWay ? "text-white" : "text-[#1D306D]"}`}
            />
            <span
              className={`px-1 sm:px-2 ${!oneWay ? "text-white" : "text-[#1D306D]"}`}
            >
              Round Trip
            </span>
          </div>
        </div>

        <form
          action=""
          className={
            oneWay
              ? "w-full max-w-screen-2xl mx-auto bg-[#F5FBFB] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-[25%_2%_25%_2%_14%_2%_17%] xl:grid-cols-[27%_2%_27%_2%_15%_2%_17%] px-4 gap-2 sm:gap-4 py-6 sm:py-8 align-middle rounded-b-xl rounded-e-xl"
              : "w-full max-w-screen-2xl mx-auto bg-[#F5FBFB] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-[20%_2%_20%_2%_14%_2%_14%_2%_10%] xl:grid-cols-[20%_2%_20%_2%_15%_2%_15%_2%_12%] px-4 gap-2 sm:gap-4 py-6 sm:py-8 align-middle rounded-b-xl rounded-e-xl"
          }
          onSubmit={(e) => {
            e.preventDefault();
            setSubmitted(true);
            if (
              flightRoutes.filter(
                (flightRoute) =>
                  flightRoute.arrivalAirport.iataCode === arrivalCity &&
                  flightRoute.departureAirport.iataCode === departureCity
              ).length === 0
            ) {
              toast.error(
                `${arrivalCity} & ${departureCity} is not a valid route.`
              );
              return;
            }

            axios
              .get(`${BASE_URL}/api/schedule`, {
                params: {
                  arrivalCity,
                  departureCity,
                  PageSize: 50,
                },
              })
              .then((response) => {
                setOneWayFlightsSchedule(response.data.data);
              });

            if (!oneWay) {
              axios
                .get(`${BASE_URL}/api/schedule`, {
                  params: {
                    departureCity: arrivalCity,
                    arrivalCity: departureCity,
                    PageSize: 50,
                  },
                })
                .then((response) => {
                  setReturnFlightsSchedule(response.data.data);
                });
            }
          }}
        >
          <div className="">
            <label htmlFor="goingFrom" className="font-medium text-[#1D306D]">
              From
            </label>
            <input
              type="text"
              name="goingFrom"
              id="goingFrom"
              className="block py-2.5 px-4 w-full border-0 text-[#8A94B2] bg-[#1D306D]/5 focus:outline-none focus:ring-0 peer rounded-md"
              list="arrival-airports-list"
              value={departureCity}
              onChange={(e) => setDepartureCity(e.currentTarget.value)}
              placeholder="Where are you headed from?"
            />
            <datalist id="arrival-airports-list">
              {flightRoutes
                .filter(
                  (flightRoute) =>
                    departureCity === "" ||
                    flightRoute.departureAirport.iataCode === departureCity
                )
                .map((flightRoute) => (
                  <option value={flightRoute.arrivalAirport.iataCode}>
                    {flightRoute.arrivalAirport.name}
                  </option>
                ))}
            </datalist>
          </div>
          <span className="hidden lg:block w-[2px] h-[90%] mx-auto bg-[#1D306D]/5 rounded-full"></span>
          <div className="">
            <label htmlFor="goingTo" className="font-medium text-[#1D306D]">
              To
            </label>
            <input
              type="text"
              name="goingTo"
              id="goingTo"
              className="block py-2.5 px-4 w-full border-0 text-[#8A94B2] bg-[#1D306D]/5 focus:outline-none focus:ring-0 peer rounded-md"
              placeholder="Where are you headed to?"
              list="departure-airports-list"
              value={arrivalCity}
              onChange={(e) => setArrivalCity(e.currentTarget.value)}
            />
            <datalist id="departure-airports-list">
              {flightRoutes
                .filter(
                  (flightRoute) =>
                    arrivalCity === "" ||
                    flightRoute.arrivalAirport.iataCode === arrivalCity
                )
                .map((flightRoute) => (
                  <option value={flightRoute.departureAirport.iataCode}>
                    {flightRoute.departureAirport.name}
                  </option>
                ))}
            </datalist>
          </div>
          <span className="hidden lg:block w-[2px] h-[90%] bg-[#1D306D]/5 rounded-full mx-auto"></span>
          <div className="">
            <label
              htmlFor="departureDate"
              className="font-medium text-[#1D306D]"
            >
              Departure
            </label>
            <input
              type="date"
              name="departureDate"
              id="departureDate"
              // onfocus="(this.type = 'date')"
              // onblur="(this.type = 'text')"
              className="block py-2.5 px-4 w-full border-0 text-[#8A94B2] bg-[#1D306D]/5 focus:outline-none focus:ring-0 peer rounded-md"
              placeholder="What's your Departure Date?"
              value={departureDate.format("YYYY-MM-DD")}
              onChange={(e) => setDepartureDate(dayjs(e.currentTarget.value))}
            />
          </div>

          <span className="hidden lg:block w-[2px] h-[90%] bg-[#1D306D]/5 rounded-full mx-auto"></span>

          {!oneWay && (
            <>
              <div className="">
                <label
                  htmlFor="returnDate"
                  className="font-medium text-[#1D306D]"
                >
                  Return
                </label>
                <input
                  type="date"
                  name="returnDate"
                  id="returnDate"
                  // onfocus="(this.type = 'date')"
                  // onblur="(this.type = 'text')"
                  className="block py-2.5 px-4 w-full border-0 text-[#8A94B2] bg-[#1D306D]/5 focus:outline-none focus:ring-0 peer rounded-md"
                  placeholder="What's your Return Date?"
                  value={returnDate.format("YYYY-MM-DD")}
                  onChange={(e) => setReturnDate(dayjs(e.currentTarget.value))}
                />
              </div>
              <span className="hidden lg:block w-[2px] h-[90%] bg-[#1D306D]/5 rounded-full mx-auto"></span>
            </>
          )}
          <button
            type="submit"
            className="bg-[#1D306D] text-white rounded-lg h-[40px] my-auto w-full sm:w-auto md:mt-[1.5rem] md:h-[44px] py-2"
          >
            Search Flights
          </button>
        </form>

        <div>
          {oneWayFlightsSchedule.length === 0 &&
          arrivalCity &&
          departureCity &&
          submitted ? (
            <div className="bg-red-100 p-5 rounded-lg mt-5">
              Sorry, One Way flights are not available at the moment.
            </div>
          ) : oneWayFlightsSchedule.length === 0 ||
            arrivalCity === "" ||
            departureCity === "" ||
            !submitted ? (
            ""
          ) : (
            <div className="one-way-flights p-5">
              <h1 className="font-bold text-xl text-primary pb-4">
                Search Results:&nbsp;
                <span className="text-secondary">
                  {departureCity} to {arrivalCity}
                </span>
              </h1>
              <div className="border rounded-md overflow-hidden">
                <table className="w-full text-sm text-gray-500 text-center">
                  <thead className="text-gray-700 uppercase bg-[#F5FBFB] border-b">
                    <tr>
                      <th className="px-6 py-3">Route</th>
                      {sevenDaysFromDepartureDate.map((date) => (
                        <th className="px-6 py-3 border-l">
                          {date.toDate().toLocaleDateString()}
                          <br />
                          <div className="font-light">
                            {date.toDate().toLocaleDateString("en-GB", {
                              weekday: "long",
                            })}
                          </div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="">
                      <td className="px-6 py-3">
                        {departureCity} to {arrivalCity}
                      </td>
                      {sevenDaysFromDepartureDate.map((date) => {
                        const flightSchedule = oneWayFlightsSchedule.find(
                          (flightSchedule) =>
                            dayjs(flightSchedule.flightDate).format(
                              "YYYY-MM-DD"
                            ) === date.format("YYYY-MM-DD")
                        );
                        return (
                          <>
                            {flightSchedule === undefined ? (
                              <td className="border-l text-xs uppercase text-black">
                                <div className="px-6 py-3 border-b-2 border-red-500">
                                  Unavailable
                                </div>
                              </td>
                            ) : (
                              <td className="border-l text-xs uppercase text-black">
                                <div className="px-6 py-3 border-b-2 border-emerald-500">
                                  Available
                                </div>
                              </td>
                            )}
                          </>
                        );
                      })}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )}
          {!oneWay && (
            <>
              {oneWayFlightsSchedule.length === 0 &&
              arrivalCity &&
              departureCity &&
              submitted ? (
                <div className="bg-red-100 p-5 rounded-lg mt-5">
                  Sorry, Round Trip flights are not available at the moment.
                </div>
              ) : oneWayFlightsSchedule.length === 0 ||
                arrivalCity === "" ||
                departureCity === "" ||
                !submitted ? (
                ""
              ) : (
                <div className="return-flights p-5">
                  <h1 className="font-bold text-xl text-primary pb-4">
                    Search Results:&nbsp;
                    <span className="text-secondary">
                      {arrivalCity} to {departureCity}
                    </span>
                  </h1>
                  <div className="border rounded-md overflow-hidden">
                    <table className="w-full text-sm text-center text-gray-500">
                      <thead className="text-gray-700 uppercase bg-[#F5FBFB] border-b">
                        <tr>
                          <th className="px-6 py-3">Route</th>
                          {sevenDaysFromReturnDate.map((date) => (
                            <th className="px-6 py-3 border-l">
                              {date.toDate().toLocaleDateString()}
                              <br />
                              <div className="font-light">
                                {date.toDate().toLocaleDateString("en-GB", {
                                  weekday: "long",
                                })}
                              </div>
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="px-6 py-3">
                            {arrivalCity} to {departureCity}
                          </td>
                          {sevenDaysFromReturnDate.map((date) => {
                            const flightSchedule = returnFlightsSchedule.find(
                              (flightSchedule) =>
                                dayjs(flightSchedule.flightDate).format(
                                  "YYYY-MM-DD"
                                ) === date.format("YYYY-MM-DD")
                            );
                            return (
                              <>
                                {flightSchedule === undefined ? (
                                  <td className="border-l text-xs uppercase text-black">
                                    <div className="px-6 py-3 border-b-2 border-red-500">
                                      Unavailable
                                    </div>
                                  </td>
                                ) : (
                                  <td className="border-l text-xs uppercase text-black">
                                    <div className="px-6 py-3 border-b-2 border-emerald-500">
                                      Available
                                    </div>
                                  </td>
                                )}
                              </>
                            );
                          })}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>

      <form
        action=""
        className="text-[#4B4F52] w-full "
        onSubmit={submitFlight}
      >
        <ToastContainer />
        <div className="shadow-xl border rounded-lg p-5 mb-7">
          <span className="text-[18px]">Find Out More About Flight</span>
          <br />
          <br />
          <br />

          <div className="md:grid md:grid-cols-2 gap-8">
            <div className="relative z-0 w-full mb-8 md:mb-5 group">
              <select
                name="salutations"
                id="salutations"
                className="block py-2.5 px-4 w-full border-0 text-[#8A94B2] bg-[#1D306D]/5 focus:outline-none focus:ring-0 peer rounded-md"
                value={salutations}
                onChange={(e) => {
                  setSalutations(e.target.value);
                }}
                required
              >
                <option value="" disabled selected>
                  Select
                </option>
                <option value="0">Mr.</option>
                <option value="1">Miss.</option>
                <option value="2">Mrs.</option>
              </select>
              <label
                htmlFor="salutations"
                className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform translate-x-2 -translate-y-8 scale-85 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-8 peer-focus:translate-x-0"
              >
                Salutations
              </label>
            </div>
            <div className="relative z-0 w-full mb-8 md:mb-5 group">
              <input
                type="text"
                name="firstName"
                id="firstName"
                className="block py-2.5 px-4 w-full border-0 text-[#8A94B2] bg-[#1D306D]/5 focus:outline-none focus:ring-0 peer rounded-md peer"
                placeholder=""
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
                required
              />
              <label
                htmlFor="firstName"
                className="peer-focus:font-medium absolute px-4 text-[#8A94B2] dark:text-gray-400 duration-300 transform -translate-y-9 scale-85 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-[#4B4F52] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-9 peer-focus:px-0"
              >
                First Name
              </label>
            </div>
            <div className="relative z-0 w-full mb-8 md:mb-5 group">
              <input
                type="text"
                name="lastName"
                id="lastName"
                className="block py-2.5 px-4 w-full border-0 text-[#8A94B2] bg-[#1D306D]/5 focus:outline-none focus:ring-0 peer rounded-md peer"
                placeholder=""
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
                required
              />
              <label
                htmlFor="lastName"
                className="peer-focus:font-medium absolute px-4 text-[#8A94B2] dark:text-gray-400 duration-300 transform -translate-y-9 scale-85 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-[#4B4F52] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-9 peer-focus:px-0"
              >
                Last Name
              </label>
            </div>

            <div className="relative z-0 w-full mb-8 md:mb-5 group">
              <input
                type="number"
                name="contactNo"
                id="contactNo"
                className="block py-2.5 px-4 w-full border-0 text-[#8A94B2] bg-[#1D306D]/5 focus:outline-none focus:ring-0 peer rounded-md peer"
                placeholder=""
                value={mobileNumber}
                onChange={(e) => {
                  setMobileNumber(e.target.value);
                }}
                required
              />
              <label
                htmlFor="contactNo"
                className="peer-focus:font-medium absolute px-4 text-[#8A94B2] dark:text-gray-400 duration-300 transform -translate-y-9 scale-85 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-[#4B4F52] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-9 peer-focus:px-0"
              >
                Mobile Number
              </label>
            </div>

            <div className="relative z-0 w-full mb-8 md:mb-5 group">
              <input
                type="email"
                name="emailAddress"
                id="emailAddress"
                className="block py-2.5 px-4 w-full border-0 text-[#8A94B2] bg-[#1D306D]/5 focus:outline-none focus:ring-0 peer rounded-md peer"
                placeholder=""
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                required
              />
              <label
                htmlFor="emailAddress"
                className="peer-focus:font-medium absolute px-4 text-[#8A94B2] dark:text-gray-400 duration-300 transform -translate-y-9 scale-85 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-[#4B4F52] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-9 peer-focus:px-0"
              >
                Email Address
              </label>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="relative z-0 w-full mb-8 md:mb-5 group">
                <select
                  name="noOfAdult"
                  id="noOfAdult"
                  className="block py-2.5 px-4 w-full border-0 text-[#8A94B2] bg-[#1D306D]/5 focus:outline-none focus:ring-0 peer rounded-md"
                  value={noOfAdult}
                  onChange={(e) => {
                    setNoOfAdult(e.target.value);
                  }}
                  required
                >
                  <option value="" disabled selected>
                    Select
                  </option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                </select>
                <label
                  htmlFor="noOfAdult"
                  className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform translate-x-2 -translate-y-8 scale-85 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-8 peer-focus:translate-x-0"
                >
                  No. of Adult *
                </label>
              </div>

              <div className="relative z-0 w-full mb-8 md:mb-5 group">
                <select
                  name="noOfChildren"
                  id="noOfChildren"
                  className="block py-2.5 px-4 w-full border-0 text-[#8A94B2] bg-[#1D306D]/5 focus:outline-none focus:ring-0 peer rounded-md"
                  value={noOfChildren}
                  onChange={(e) => {
                    setNoOfChildren(e.target.value);
                  }}
                >
                  <option value="" disabled selected>
                    Select
                  </option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                </select>
                <label
                  htmlFor="noOfChildren"
                  className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform translate-x-2 -translate-y-8 scale-85 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-8 peer-focus:translate-x-0"
                >
                  No. of Children
                </label>
              </div>
            </div>
            <div className="relative z-0 w-full mb-8 md:mb-5 group col-span-2">
              <textarea
                type="textarea"
                name="yourMessage"
                id="yourMessage"
                className="block py-2.5 px-4 w-full border-0 text-[#8A94B2] bg-[#1D306D]/5 focus:outline-none focus:ring-0 peer rounded-md peer"
                placeholder=""
                value={yourMessage}
                onChange={(e) => {
                  setYourMessage(e.target.value);
                }}
                required
              />
              <label
                htmlFor="yourMessage"
                className="peer-focus:font-medium absolute px-4 text-[#8A94B2] dark:text-gray-400 duration-300 transform -translate-y-9 scale-85 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-[#4B4F52] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-9 peer-focus:px-0"
              >
                Your Message
              </label>
            </div>
          </div>
        </div>
        <div className="w-[162px] mx-auto">
          <input
            type="submit"
            value="Make Inquiry"
            className="w-full mx-auto bg-secondary text-white rounded-full py-4"
          />
        </div>
      </form>
    </div>
  );
};

export default Flight;
