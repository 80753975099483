import React from "react";

import { BASE_URL } from "../../utils/axios/axios";

const SubDestination = ({ data }) => {
  console.log(data);
  return (
    <div className="w-[85vw] mx-auto pt-14">
      <div>
        <h1 className="md:text-5xl text-4xl text-[#1D306D] pb-6">
          {data.name}
        </h1>
        <p className="md:text-lg text-[#8A94B2] pb-6">{data.description}</p>
      </div>

      <a
        href={`/destination/${data.id}`}
        className="bg-secondary text-white text-sm p-4 rounded-full font-bold"
      >
        Learn More !
      </a>

      <div className="max-w-screen-2xl my-10 mx-auto grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 gap-6">
        {data.images
          .reverse()
          .slice(0, 4)
          .map((image, key) => {
            return (
              <div key={key} className="w-full h-[35vh]">
                <img
                  src={`${BASE_URL}/${image.filePath}`}
                  alt={image.altinfo}
                  className="rounded-3xl w-full h-full object-cover"
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default SubDestination;
