import parse from "html-react-parser";
import React, { useEffect, useState } from "react";
import { IoCalendarNumberOutline } from "react-icons/io5";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import "../../styles/tourPackageStyles.css";
import { BASE_URL } from "../../utils/axios/axios";

function TourNextArrow(props) {
  const { onClick } = props;
  return (
    <div
      className={`absolute z-50 top-[40%] 2xl:-right-20 right-0 border-2 p-2 bg-primary text-4xl text-white rounded-2xl cursor-pointer`}
      style={{ display: "block" }}
      onClick={onClick}
    >
      <MdChevronRight />
    </div>
  );
}

function TourPrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      className={`absolute z-50 top-[40%] 2xl:-left-20 left-0 border-2 p-2  bg-primary text-4xl text-white rounded-2xl cursor-pointer`}
      style={{ display: "block" }}
      onClick={onClick}
    >
      <MdChevronLeft />
    </div>
  );
}

const TourPackageCarousel = ({ packages }) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [search, setSearch] = useState("");
  const [slides, setSlides] = useState();

  const pubPackages = packages.filter((packages) => packages.published);
  const packageChoice = packages.filter((packageName) =>
    packageName.title.toLowerCase().includes(search)
  );

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: slides >= 6 ? 6 : slides,
    slidesToScroll: 1,
    focusOnSelect: true,
    nextArrow: <TourNextArrow />,
    prevArrow: <TourPrevArrow />,
    beforeChange: (current, next) => setActiveSlide(next),
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  useEffect(() => {
    if (search === "") {
      setSlides(pubPackages.length);
    } else {
      setSlides(packageChoice.length);
    }
  }, [pubPackages, packageChoice, search]);

  return (
    <>
      <div className="text-[#1D306D] mx-auto text-center py-10">
        <h1 className="text-[56px]">Our Packages</h1>
        <span className="text-[#8A94B2] text-[20px]">
          See What Our Packages Have To Offer
        </span>
      </div>
      <div className="w-[90%] 2xl:w-[60%] mx-auto bg-[#F5FBFB] grid mt-10 px-8 gap-2 py-8 rounded-xl">
        {/* <div className="">
          <label htmlFor="sortBy" className="font-medium text-[#1D306D] py-4">
            Sort By
          </label>
          <select
            name="sortBy"
            id="sortBy"
            className="block py-2.5 px-4 w-full text-[#8A94B2] bg-[#1D306D]/5"
          >
            <option value="Default" selected>
              Default
            </option>
            <option value="Name">Name</option>
            <option value="Date">Date</option>
          </select>
        </div> 
        <span className="w-[2px] h-full mx-auto bg-[#c9c9c9] rounded-full"></span> */}

        <div>
          <label
            htmlFor="searchPackage"
            className="font-medium text-[#1D306D] py-4"
          >
            Search
          </label>
          <input
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            type="text"
            name="searchPackage"
            id="searchPackage"
            className="block py-2.5 px-4 w-full text-[#8A94B2] bg-[#1D306D]/5"
            placeholder="Search By Packages"
          />
        </div>
      </div>
      {search !== "" && packageChoice.length === 0 ? (
        <p className="text-center font-bold text-2xl">
          That Package is not available
        </p>
      ) : (
        <div className="max-w-screen-2xl mx-auto md:px-10 px-5 relative flex flex-col pb-24">
          {pubPackages.length !== 0 && (
            <>
              <div className="pt-20 pb-10 w-full z-10 relative">
                <div
                  className={
                    slides >= 6 && "ml-auto xl:pl-14 xl:w-3/5 z-0 relative"
                  }
                >
                  <div className="mb-5 text-2xl sm:text-[56px] leading-[1.25] text-[rgb(75,79,82)]">
                    {search === ""
                      ? pubPackages[activeSlide].title
                      : packageChoice[activeSlide].title}
                    &nbsp;Tour Package
                  </div>
                  <div className="mb-5 text-secondary text-lg">
                    <IoCalendarNumberOutline className="inline" />
                    &nbsp;
                    {search === ""
                      ? `${pubPackages[activeSlide].tripDays} Days / ${pubPackages[activeSlide].tripNights} Nights`
                      : `${packageChoice[activeSlide].tripDays} Days / ${packageChoice[activeSlide].tripNights  } Nights`}
                  </div>
                  <div className="mb-5 text-[#8A94B2] text-lg text-justify">
                    {parse(
                      (search === "" ? pubPackages : packageChoice)[
                        activeSlide
                      ].description.substring(0, 500) +
                        `${
                          (search === "" ? pubPackages : packageChoice)[
                            activeSlide
                          ].description.length > 500
                            ? "..."
                            : ""
                        }`
                    )}
                  </div>
                  <div className="mb-5 flex flex-row gap-x-3">
                    <a
                      href={`/package/${pubPackages[activeSlide].slug}/request`}
                      className="bg-secondary text-white rounded-full p-4"
                    >
                      Book Package
                    </a>
                    <a
                      className="text-secondary border-2 border-secondary rounded-full p-4"
                      style={{ backgroundColor: "white" }}
                      href={`/package/${pubPackages[activeSlide].slug}`}
                    >
                      View Details
                    </a>
                  </div>
                </div>
              </div>
              {slides === 1 ? (
                <>
                  {(search === "" ? pubPackages : packageChoice).map(
                    (image, index) => (
                      <img
                        key={index}
                        src={
                          image.images.length > 0
                            ? `${BASE_URL}/${image.images[0].filePath}`
                            : require("../../assets/images/DrukairLogo.png")
                        }
                        alt={
                          image.images.length > 0
                            ? image.images[0].altInfo
                            : "Image unavailable"
                        }
                        className={`w-full h-[50vh] object-cover border-[0.2px] border-[#d1d1d1] ${index === activeSlide && slides >= 6 ? "rounded-[20px] " : "rounded-[40px]"}`}
                      />
                    )
                  )}
                </>
              ) : (
                <div
                  className={`${slides >= 6 && "tourPackageStyles"} z-10 relative min-h-[30vh]`}
                >
                  <Slider {...settings} className="top-0 z-10 w-full">
                    {(search === "" ? pubPackages : packageChoice).map(
                      (image, index) => (
                        <div
                          key={index}
                          className={`h-[30vh] max-h-[50vh] max-w-[90%] cursor-pointer block-pls mx-auto ${index === activeSlide && slides >= 6 ? "xl:scale-[2.2] " : slides >= 6 ? "xl:scale-[0.9] xl:float-right xl:origin-right" : ""}`}
                        >
                          <img
                            src={
                              image.images.length > 0
                                ? `${BASE_URL}/${image.images[0].filePath}`
                                : require("../../assets/images/DrukairLogo.png")
                            }
                            alt={
                              image.image
                                ? image.images[0].altInfo
                                : "Image unavailable"
                            }
                            className={`w-full h-full border-[0.2px] border-[#d1d1d1] ${index === activeSlide && slides >= 6 ? "rounded-[20px] " : "rounded-[40px]"} ${
                              image.images.length > 0
                                ? "object-cover"
                                : "object-contain px-5 bg-white"
                            }`}
                          />
                        </div>
                      )
                    )}
                  </Slider>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default TourPackageCarousel;
