import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import "../../styles/destinationStyle.css";
import { BASE_URL } from "../../utils/axios/axios";

const ImageRendering = ({ destination }) => {
  const [image, setImage] = useState(false);
  const [firstBatch, setFirstBatch] = useState(false);
  const [secondBatch, setSecondBatch] = useState(false);
  const [isNotMobileView, setNotMobileView] = useState(false);
  // console.log(destination);

  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 3000,
    pauseOnHover: false,
  };

  useEffect(() => {
    const handleResize = () => {
      setNotMobileView(window.innerWidth >= 640); // 2xl breakpoint
    };

    // Initial check on mount
    handleResize();

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const delayTimer = setTimeout(() => {
      setImage(true);
    }, 500);

    return () => clearTimeout(delayTimer);
  }, []);

  useEffect(() => {
    if (image) {
      const firstTimer = setTimeout(() => setFirstBatch(true), 1000);
      const secondTimer = setTimeout(() => setSecondBatch(true), 1500);
      return () => {
        clearTimeout(firstTimer);
        clearTimeout(secondTimer);
      };
    }
  }, [image]);
  
  return (
    <div className="relative w-full md:h-[85vh] h-[95vh] -top-[5rem]">
      <div className="absolute left-0 right-0 mx-auto grid grid-cols-1 content-center h-screen text-white text-center text-[60px] sm:text-[90px] md:text-[130px] cursor-pointer">
        {isNotMobileView ? (
          <>
            {destination.images && (
              <div className="absolute left-0 right-0 mx-auto w-[90vw] lg:w-[80vw] z-0 h-screen grid content-center grid-cols-[35%_25%_36%] grid-rows-[27vh_30vh_8vh] gap-4 lg:gap-6 px-20">
                {destination.images[0] && (
                  <img
                    src={`${BASE_URL}/${destination.images[0].filePath}`}
                    alt={destination.images[0].altinfo}
                    className={`rounded-2xl w-full h-full object-cover fade-in ${firstBatch && "visible"}`}
                  />
                )}
                {destination.images[1] && (
                  <img
                    src={`${BASE_URL}/${destination.images[1].filePath}`}
                    alt={destination.images[1].altinfo}
                    className={`rounded-2xl w-full h-full object-cover fade-in-second ${secondBatch && "visible-second"}`}
                  />
                )}
                {destination.images[2] && (
                  <img
                    src={`${BASE_URL}/${destination.images[2].filePath}`}
                    alt={destination.images[2].altinfo}
                    className={`rounded-2xl w-full h-full row-span-3 object-cover fade-in ${firstBatch && "visible"}`}
                  />
                )}
                {destination.images[3] && (
                  <img
                    src={`${BASE_URL}/${destination.images[3].filePath}`}
                    alt={destination.images[3].altinfo}
                    className={`rounded-2xl w-full h-full row-span-2 object-cover fade-in-second ${secondBatch && "visible-second"}`}
                  />
                )}
                {destination.images[4] && (
                  <img
                    src={`${BASE_URL}/${destination.images[4].filePath}`}
                    alt={destination.images[4].altinfo}
                    className={`rounded-2xl w-full h-full row-span-2 object-cover fade-in ${firstBatch && "visible"}`}
                  />
                )}
              </div>
            )}
            <span className="uppercase bg-[#adadad]/60 backdrop-blur-xl rounded-2xl lg:px-20 sm:px-10 px-5 mx-auto z-10">
              {destination.name}
            </span>
          </>
        ) : (
          <div className="destinationStyle relative w-full min-h-[100vh]">
            {destination.images && (
              <Slider
                {...settings}
                className="absolute top-0 left-0 w-full h-full z-0"
              >
                {destination.images.map((item, index) => (
                  <img
                    key={index}
                    className="object-cover w-full h-full"
                    src={`${BASE_URL}/${item.filePath}`}
                    alt={item.altInfo}
                  />
                ))}
              </Slider>
            )}
            <span className="uppercase bg-[#adadad]/60 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 backdrop-blur-xl rounded-2xl lg:px-20 sm:px-10 px-5 py-2 mx-auto z-10 text-center">
              {destination.name}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageRendering;
