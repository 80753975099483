import React from "react";

import Memorable from "../../components/tourPackage/memorable";
import TourPackageCarousel from "../../components/tourPackage/tourPackageCarousel";
import useAxiosAuth from "../../utils/axios/useAxiosAuth";
import Landing from "./landing";

const TourPackages = () => {
  const { data: packages } = useAxiosAuth(`Package`);

  return (
    // Landing of Tour Package
    <div className="w-dvw">
      <Landing />

      {/* Components */}
      {packages.length > 0 && <TourPackageCarousel packages={packages} />}

      <Memorable />
    </div>
  );
};

export default TourPackages;
