import React, { useState } from "react";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import { useParams } from "react-router-dom";
import Slider from "react-slick";

import CarouselOverlay from "../../components/general/carouselOverlay";
import Loader from "../../components/general/loader";
import PackageNav from "../../components/packages/packageNav";
import { BASE_URL } from "../../utils/axios/axios";
import useAxiosAuth from "../../utils/axios/useAxiosAuth";
import NotFound from "../notFound";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div
      className={`absolute top-[12vh] right-[-3vw] z-[1] text-4xl text-white rounded-lg cursor-pointer bg-secondary`}
      style={{ display: "block" }}
      onClick={onClick}
    >
      <MdChevronRight />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      className={`absolute top-[12vh] left-[-3vw] z-[1] text-4xl text-white rounded-lg cursor-pointer bg-secondary`}
      style={{ display: "block" }}
      onClick={onClick}
    >
      <MdChevronLeft />
    </div>
  );
}
const Package = () => {
  const { packageSlug } = useParams();
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);

  const { data: packageInformation, error } = useAxiosAuth(
    `Package/${packageSlug}`
  );

  const openOverlay = () => {
    setIsOverlayOpen(true);
  };

  const closeOverlay = () => {
    setIsOverlayOpen(false);
  };
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  return (
    <div className="min-h-[42.4vh]">
      {error.length !== 0 ? (
        <NotFound />
      ) : packageInformation ? (
        <>
          <div className="max-w-screen-2xl mx-auto md:px-10 px-5 pt-16">
            <div className="md:grid md:grid-cols-3 md:max-h-[60vh] xl:gap-8 gap-5 md:space-y-0 space-y-6 content-center hidden">
              <div className="col-span-2">
                <img
                  className="w-full xl:h-[61.5vh] md:h-[60vh] h-[40vh] object-cover rounded-xl"
                  src={
                    packageInformation.images &&
                    packageInformation.images.length > 0
                      ? `${BASE_URL}/${packageInformation.images[0].filePath}`
                      : "https://placehold.co/600x400.png"
                  }
                  alt="package"
                />
              </div>

              <div className="md:flex md:flex-col xl:gap-8 gap-5">
                <img
                  className="h-fit md:max-h-[29vh] md:h-[29vh] object-cover rounded-xl"
                  src={
                    packageInformation.images &&
                    packageInformation.images.length > 1
                      ? `${BASE_URL}/${packageInformation.images[1].filePath}`
                      : "https://placehold.co/600x400.png"
                  }
                  alt="package"
                />
                <button
                  onClick={
                    packageInformation.images &&
                    packageInformation.images.length > 3 &&
                    openOverlay
                  }
                  className="h-[29vh] rounded-xl grid justify-items-center relative"
                >
                  {packageInformation.images &&
                    packageInformation.images.length > 3 && (
                      <p className="absolute z-50 top-[45%] text-white font-bold text-4xl ">
                        {packageInformation.images.length - 3}+
                      </p>
                    )}
                  <img
                    className={`h-[29vh] w-full object-cover rounded-xl ${
                      packageInformation.images &&
                      packageInformation.images.length > 3
                        ? "brightness-50"
                        : ""
                    }`}
                    src={
                      packageInformation.images &&
                      packageInformation.images.length > 2
                        ? `${BASE_URL}/${packageInformation.images[2].filePath}`
                        : "https://placehold.co/600x400.png"
                    }
                    alt="package"
                  />
                </button>
              </div>
            </div>

            <div className="md:hidden">
              {packageInformation.images &&
              packageInformation.images.length > 1 ? (
                <Slider {...settings}>
                  {packageInformation.images?.map((image, index) => (
                    <div key={index}>
                      <img
                        className="h-[28vh] w-full rounded-xl object-cover"
                        src={`${BASE_URL}/${image.filePath}`}
                        alt={image.altInfo}
                      />
                    </div>
                  ))}
                </Slider>
              ) : (
                <img
                  className="w-full xl:h-[61.5vh] md:h-[60vh] h-[40vh] object-cover rounded-xl"
                  src={
                    packageInformation.images &&
                    packageInformation.images.length > 0
                      ? `${BASE_URL}/${packageInformation.images[0].filePath}`
                      : "https://placehold.co/600x400.png"
                  }
                  alt="package"
                />
              )}
            </div>
          </div>
          <CarouselOverlay
            images={packageInformation.images}
            isOpen={isOverlayOpen}
            onClose={closeOverlay}
          />
          <PackageNav packageInformation={packageInformation} />
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default Package;
