import React, { useEffect, useState } from "react";
import { AiOutlineLike } from "react-icons/ai";
import { AiFillPlusCircle } from "react-icons/ai";
import { FaBusinessTime } from "react-icons/fa";
import { FaCar } from "react-icons/fa";
import { IoWifiOutline } from "react-icons/io5";
import { RiWindyLine } from "react-icons/ri";
import { TbSwimming } from "react-icons/tb";

const Overview = ({ packageInformation }) => {
  const [showFacilities, setShowFacilities] = useState(false);
  let facilities = [
    {
      icon: <IoWifiOutline />,
      text: "Free Wifi",
      isVisible: packageInformation.freeWifi,
    },
    {
      icon: <FaBusinessTime />,
      text: "Business Services",
      isVisible: packageInformation.businessServices,
    },
    {
      icon: <RiWindyLine />,
      text: "Air Conditioning",
      isVisible: packageInformation.airConditioning,
    },
    {
      icon: <TbSwimming />,
      text: "Swimming Pool",
      isVisible: packageInformation.swimmingPool,
    },
    {
      icon: <FaCar />,
      text: "Parking Available",
      isVisible: packageInformation.parkingAvailable,
    },
    {
      icon: <AiOutlineLike />,
      text: "Top Rated in Area",
      isVisible: packageInformation.topRatedInArea,
    },
  ];
  useEffect(() => {
    if (
      packageInformation.freeWifi ||
      packageInformation.businessServices ||
      packageInformation.airConditioning ||
      packageInformation.swimmingPool ||
      packageInformation.parkingAvailable ||
      packageInformation.topRatedInArea
    ) {
      setShowFacilities(true);
    }
  }, [
    packageInformation.freeWifi,
    packageInformation.businessServices,
    packageInformation.airConditioning,
    packageInformation.swimmingPool,
    packageInformation.parkingAvailable,
    packageInformation.topRatedInArea,
  ]);

  return (
    <div className="block md:w-[70vw] w-[90vw] md:mt-0 mt-8 p-6 border bg-[#FFFFFF] rounded-lg  md:mb-0 mb-8">
      <h5 className="mb-2 ">Overview</h5>
      <div
        className=" text-sm text-[#8A94B2] pb-8"
        dangerouslySetInnerHTML={{
          __html: packageInformation.description,
        }}
      ></div>
      {packageInformation.activities &&
        packageInformation.activities.length > 0 && (
          <div className="pt-5 border-t border-gray-300">
            <h1>Activities</h1>
            <div className="md:grid md:grid-cols-3 xl:flex pt-4 2xl:space-x-12 xl:space-x-5 space-x-0 text-sm text-[#8A94B2] xl:space-y-0 space-y-2 content-start">
              {packageInformation.activities.map((activity, index) => (
                <p key={index} className="flex items-center">
                  <span className="text-secondary opacity-50 pr-1">
                    <AiFillPlusCircle />
                  </span>
                  <span key={index}>{activity.name}</span>
                </p>
              ))}
            </div>
          </div>
        )}
      {showFacilities && (
        <div className="pt-5 border-t border-gray-300">
          <h1>Top Facilities</h1>
          <div className="md:grid md:grid-cols-3 xl:flex pt-4 2xl:space-x-12 xl:space-x-5 space-x-0 text-sm text-[#8A94B2] xl:space-y-0 space-y-2 content-start">
            {facilities.map((item, index) => (
              <>
                {item.isVisible && (
                  <p key={index} className="flex items-center">
                    <span className="text-secondary">{item.icon}</span>
                    <span className="px-2">{item.text}</span>
                  </p>
                )}
              </>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Overview;
