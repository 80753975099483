import React from "react";
import { useParams } from "react-router-dom";

import ImageRendering from "../components/destination/imageRendering";
import TourPackageCarousel from "../components/tourPackage/tourPackageCarousel";
import useAxiosAuth from "../utils/axios/useAxiosAuth";

const Destination = () => {
  const { destinationID } = useParams();
  const { data: destination } = useAxiosAuth(`Destination/${destinationID}`);
  const { data: packages } = useAxiosAuth(`Package`);
  const filteredPackages = packages.filter(
    (item) => item.destinationIds == destinationID
  );

  console.log(filteredPackages);
  return (
    <>
      <ImageRendering destination={destination} />

      <p className="max-w-screen-2xl mx-auto md:px-10 px-5 text-xl text-center text-[#8A94B2] ">
        {destination.description}
      </p>

      {/* Components */}
      {packages.length > 0 && (
        <TourPackageCarousel packages={filteredPackages} />
      )}
    </>
  );
};

export default Destination;
