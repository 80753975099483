import React from "react";
import { FaWhatsapp } from "react-icons/fa";

const Whatsapp = () => {
  return (
    <a
      className="fixed text-[#25d366] bottom-5 right-5 z-[99] bg-slate-100 opacity-90 hover:opacity-100 transition ease-in-out shadow-md shadow-[#25d36569] border border-[#25d366] rounded-full p-3 hover:scale-105 "
      href="https://api.whatsapp.com/send/?phone=6584941625"
      target="_blank"
      rel="noreferrer"
    >
      <FaWhatsapp className="lg:h-[2.2vw] lg:w-[2.2vw] h-8 w-8" />
    </a>
  );
};

export default Whatsapp;
