import React from "react";

import contactSvg from "../../assets/images/contactSvg.svg";
import ContactCarousel from "./contactCarousel";
import Destinations from "./destinations";
// import Excellence from "./excellence";

const Contact = () => {
  return (
    <div className=" pb-20">
      <div className="h-[80vh] bg-cover">
        <img
          src={require("../../assets/images/contact.jpg")}
          alt=""
          className="absolute top-0 h-[80vh] w-full object-cover"
        />
        <img
          className="left-0 bottom-[20vh] absolute w-screen"
          src={contactSvg}
          alt="/"
        />
      </div>
      {/* <div className="pb-16">
        <Excellence />
      </div> */}
      <ContactCarousel />
      <Destinations />
    </div>
  );
};

export default Contact;
