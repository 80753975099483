import parse from "html-react-parser";
import React from "react";
import { FaRegUser } from "react-icons/fa";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import { useParams } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import specialSVG from "../../assets/images/specialSvg.svg";
import "../../styles/styles.css";
import { BASE_URL } from "../../utils/axios/axios";
import useAxiosAuth from "../../utils/axios/useAxiosAuth";

const BlogPage = () => {
  const { blogId } = useParams();
  // console.log(blogId);

  const { data: blogs } = useAxiosAuth("blogpost");

  const activeBlog = blogs ? blogs.find((blog) => blog.id === blogId) : null;
  // console.log(activeBlog && activeBlog.thumbnail.filePath);

  function SampleNextArrow(props) {
    const { onClick } = props;
    return (
      <div
        className={`absolute top-[-10vh] right-[5vw] border-2 p-2 bg-[#DF5C26] text-4xl text-white rounded-2xl cursor-pointer`}
        style={{ display: "block" }}
        onClick={onClick}
      >
        <MdChevronRight />
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
      <div
        className={`absolute top-[-10vh] right-[25vw] md:right-[13vw] border-2 p-2 border-[#DF5C26] text-4xl text-[#DF5C26] rounded-2xl cursor-pointer`}
        style={{ display: "block" }}
        onClick={onClick}
      >
        <MdChevronLeft />
      </div>
    );
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1324,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <div className="bg-[#f7f7f7]">
      <div className="h-screen bg-cover">
        {activeBlog && (
          <img
            src={`${BASE_URL}/${activeBlog.thumbnail.filePath}`}
            alt=""
            className="absolute top-0 h-full w-full brightness-[.40] object-cover"
          />
        )}
        <img
          className="absolute bottom-[-1px] left-0 w-full"
          src={specialSVG}
          alt="/"
        />
        {activeBlog && (
          <div className="lg:grid grid-cols-2 gap-4 mt-[-10vh] md:mt-[-7vh] lg:mt-0 p-8 content-center h-full relative z-10">
            <div className="text-white lg:w-[43vw] w-[80vw] mx-auto">
              <h1 className="lg:text-5xl text-4xl mb-2 lg:mb-4">
                {activeBlog.title}
              </h1>
              <span className="lg:text-xl mb-6 block">{activeBlog.author}</span>
              <div className="lg:text-lg mb-4">
                {parse(activeBlog.content.substring(0, 700))}
              </div>
            </div>
          </div>
        )}
      </div>

      {activeBlog && (
        <div className="blog max-w-screen-2xl mx-auto px-4 lg:px-10">
          <div className="py-10 text-justify">
            <div className="table-container text-left md:text-justify">
              {parse(activeBlog.content)}
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 pb-10">
            <div>
              <img
                src={require(`../../assets/images/bhutan-third.png`)}
                alt=""
                className="w-full object-cover h-[60vh] rounded-xl"
              />
            </div>
            <div>
              <img
                src={require(`../../assets/images/bhutan-fourth.png`)}
                alt=""
                className="w-full object-cover h-[60vh] rounded-xl"
              />
            </div>
          </div>
        </div>
      )}

      <div className="mt-8 max-w-screen-2xl mx-auto px-4 lg:px-10 pb-10">
        <h1 className="lg:text-5xl text-3xl mb-10 text-primary">Other Blogs</h1>

        {blogs.length > 0 && (
          <Slider {...settings}>
            {blogs.map((item, index) => (
              <a
                href={`./${item.id}`}
                key={index}
                className="rounded-xl text-center shadow-lg max-w-[96%] mx-auto mb-4"
              >
                <div className="h-[28vh]">
                  <img
                    className="rounded-t-xl object-cover w-full h-full"
                    src={`${BASE_URL}/${item.thumbnail.filePath}`}
                    alt={item.thumbnail.alt}
                  />
                </div>

                <div className="flex flex-col gap-5 p-6">
                  <h1 className="text-black font-semibold text-left text-xl">{item.title}</h1>
                  <span className="text-[#DF5C26] flex items-center">
                    <FaRegUser className="mr-2" /> {item.author}
                  </span>
                  {/* <div className="flex w-full justify-between text-left">
                    <div>
                      <p className="text-black2">
                        {parse(item.content.substring(0, 150) + "...")}
                      </p>
                    </div>
                  </div> */}
                </div>
              </a>
            ))}
          </Slider>
        )}
      </div>
    </div>
  );
};

export default BlogPage;
